<template>
  <a-modal
      :title="isEditing ?'Kategori Düzenle': 'Kategori Ekle'"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      width="800px"
  >


    <a-row type="flex">

      <a-col :flex="2">
        Kategori İsimi
      </a-col>

      <a-col>
        <a-input v-model:value="categoryName" style="width: 600px"></a-input>
      </a-col>

    </a-row>

    <a-row type="flex">

      <a-col :flex="2">
        Kategori Açıklaması
      </a-col>

      <a-col>
        <a-input v-model:value="categoryDescription" style="width: 600px"></a-input>
      </a-col>

    </a-row>

    <template #footer>
      <a-button key="iptal" @click="visible = false">İptal</a-button>
      <a-button key="submit" type="primary" :loading="confirmLoading" @click="handleOk">
        {{ isEditing ? 'Düzenle' : 'Oluştur' }}
      </a-button>
    </template>

  </a-modal>
</template>

<script>
import {computed, ref} from 'vue'
import {useRoute} from 'vue-router'
import axios from 'axios'

export default {
  name: 'AddOrEditCategory',
  emits: ['loadCategories'],
  setup(props, {emit}) {


    const isEditing = ref(false)
    const route = useRoute()

    const visible = ref(false)
    const confirmLoading = ref(false)

    const id = ref(null)
    const categoryName = ref('')
    const categoryDescription = ref('')


    const showModal = async (_selectedCategory) => {

      visible.value = true
      if (_selectedCategory) {
        isEditing.value = true
        id.value = _selectedCategory.id
        categoryName.value = _selectedCategory.name
        categoryDescription.value = _selectedCategory.description
      }


    }

    const createCategory = async () => {
      return axios.post('/categories', {
        name: categoryName.value,
        categoryDescription: categoryDescription.value
      })
    }

    const updateCategory = async () => {
      return axios.post('/categories/update', {
        id: id.value,
        name: categoryName.value,
        description: categoryDescription.value
      })
    }


    const handleOk = () => {

      confirmLoading.value = true

      let funcToBeCalled = createCategory

      if (isEditing.value) {
        funcToBeCalled = updateCategory

      }

      funcToBeCalled()
          .then(() => {

            emit('loadCategories')

          })
          .finally(() => {
            visible.value = false
            confirmLoading.value = false
          })


    }


    return {
      categoryName,
      categoryDescription,
      isEditing,
      visible,
      confirmLoading,
      showModal,
      handleOk
    }

  }


}
</script>
